(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .value('FlyersCurrencyValue', {
            EURO: "€",
            DOLLAR: "$",
            REAL: "R$",
            LIBRA: "£",
            NOVO_SOL_PERUANO: "S/"
        });
})();
